<template>
  <div class="d-flex flex-column gap-3">
    <v-card
      elevation="0"
      class="d-flex align-center gap-x-4 flex-wrap rounded-lg pa-2"
    >
      <div class="d-flex gap-3 align-center flex-grow-1">
        <div>
          <v-skeleton-loader
            v-if="!party"
            height="60px"
            :width="`${60 * (16 / 9)}px`"
            class="rounded-lg"
            type="image"
          />
          <v-img
            v-else
            class="rounded-lg"
            :aspect-ratio="16 / 9"
            :src="party?.cover"
            height="60px"
            :width="`${60 * (16 / 9)}px`"
          />
        </div>

        <div>
          <v-skeleton-loader
            v-if="!party"
            width="100px"
            height="12px"
            class="rounded-lg mb-1"
            type="card"
          />
          <p v-else class="mb-0 text-overline lh-1">
            {{ party?.date | date("DD/MM/YY - HH:mm") }}
          </p>
          <v-skeleton-loader
            v-if="!party"
            width="180px"
            height="24px"
            class="rounded-lg"
            type="card"
          />
          <h6 v-else class="mb-0">{{ party?.name }}</h6>
        </div>
      </div>
      <div
        class="d-flex flex-wrap flex-grow-1 flex-md-grow-0 flex-shrink-1 gap-1"
      >
        <v-btn
          :disabled="!party"
          class="flex-grow-1"
          color="primary"
          text
          :to="{
            name: 'admin.entrance.management',
            params: { partyId: partyId },
          }"
        >
          Relatório
        </v-btn>
      </div>
    </v-card>

    <entries-sessions :loading="loading" :party="party" />
    <TecnicianDaily position="1" :party="party" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EntriesSessions from "../../../components/admin/party/entrance/EntriesSessions.vue";
import TecnicianDaily from "@/components/global/tecnician/TecnicianDaily.vue";

export default {
  components: { EntriesSessions, TecnicianDaily },
  metaInfo: { title: "Sessões de Entrada" },
  data: () => ({ party: null, loading: true }),
  methods: {
    ...mapActions("organization", ["partyById"]),
    async getParty() {
      this.loading = true;
      this.party = await this.partyById(this.partyId);
      this.$root.$emit("setImgBg", this.party.cover);
      this.loading = false;
    },
    scan() {
      this.$router.push({
        name: "admin.entrance.scan",
        params: { partyId: this.partyId },
      });
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("organization", ["selectedOrganization"]),
    partyId() {
      return this.$route.params.partyId;
    },
  },

  watch: {
    "selectedOrganization.id"() {
      this.$router.push({ name: "admin.entrance" });
    },
  },
  mounted() {
    this.getParty();
  },
  destroyed() {
    this.$root.$emit("setImgBg", null);
  },
};
</script>
